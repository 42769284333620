var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex row m-0 justify-space-between align-center"},[_c('AdminPageButtons',{on:{"change":function($event){return _vm.$emit('change', _vm.page)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('TypesTasksForm',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Button',{staticClass:"admin-addition-button",attrs:{"image":_vm.plus,"text":_vm.$t('admin.type-settings.add.title')}})]},proxy:true}])})],1),_c('Table',{staticClass:"px-5 py-3",attrs:{"data":_vm.typesPageable,"fields":_vm.tableFields,"formattedColumns":['icon', 'name', 'tasks', 'buttons'],"isLoading":_vm.loading,"stickyHeader":"52.5rem"},on:{"newPageOption":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var rowData = ref.rowData;
return [_c('TypeIcon',{attrs:{"type":rowData,"table":""}})]}},{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.name)+" ")]}},{key:"tasks",fn:function(ref){
var rowData = ref.rowData;
return [_c('PageableTasks',{attrs:{"pageableTasks":rowData.pageableTasks,"typeId":rowData.id}})]}},{key:"buttons",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex row justify-space-around m-0"},[_c('TypesTasksForm',{attrs:{"isAddition":false,"type":rowData},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('EditButton',{attrs:{"iconType":"edit","isLarge":""}})]},proxy:true}],null,true)}),_c('div',{staticClass:"delete-button-container"},[(!rowData.technical)?_c('DeleteType',{attrs:{"type":rowData},on:{"deleteFinished":_vm.fetch}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }