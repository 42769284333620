
















import { TypeIcon } from '@/model/enums/type-icon';
import Vue from 'vue';

export default Vue.extend({
  name: 'IconSelection',
  props: {
    selected: String,
  },
  computed: {
    iconList() {
      return Object.keys(TypeIcon);
    },
  },
  methods: {
    modify(icon: string) {
      this.$emit('modify', icon);
    },
  },
});
