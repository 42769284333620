export default class TypeTask {
  public id: number | null;
  public taskOrder: number;
  public taskName: string;

  constructor(taskOrder: number, taskName: string, id: number | null) {
    this.id = id;
    this.taskOrder = taskOrder;
    this.taskName = taskName;
  }
}
