


























































import Vue from 'vue';
import FormBase from '@/components/Form/Form.vue';
import IconSelection from '@/components/Admin/TypesTasks/IconSelection.vue';
import AdminTaskCard from '@/components/Admin/TypesTasks/AdminTaskCard.vue';
import RequirementType from '@/model/requirement-type';
import TypeTask from '@/model/type-task';
import TypeModificationRequest from '@/model/request/type-modification-request';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { required } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import { notEmptyList } from '@/validators/common';

export default Vue.extend({
  name: 'TypesTasksForm',
  components: {
    FormBase,
    IconSelection,
    AdminTaskCard,
    LoadingIndicator,
    draggable,
  },
  props: {
    type: Object as () => RequirementType,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      tasksLoading: false,
      form: {
        icon: '',
        name: '',
        tasks: [] as TypeTask[],
      },
    };
  },
  validations: {
    form: {
      icon: {
        required,
      },
      name: {
        required,
      },
      tasks: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.tasks);
        },
      },
    },
  },
  computed: {
    ...mapGetters({ typeTasks: 'adminTypeStorage/getTypeTasks' }),
    iconError(): string {
      return this.submitted && !(this.$v.form.icon as any).required
        ? this.$t('admin.type-settings.modify.icon-required').toString()
        : '';
    },
    taskNameError(): string {
      return this.submitted && !(this.$v.form.name as any).required
        ? this.$t('admin.type-settings.modify.name-required').toString()
        : '';
    },
    taskError(): string {
      return this.submitted && !(this.$v.form.tasks as any).notEmptyList
        ? this.$t('admin.type-settings.modify.task-required').toString()
        : '';
    },
    largestOrder(): number {
      return this.form.tasks.length > 0
        ? this.form.tasks.reduce((a, b) => {
            return a.taskOrder > b.taskOrder ? a : b;
          }).taskOrder
        : 0;
    },
    taskList: {
      get(): TypeTask[] {
        return (this as any).form.tasks;
      },
      set(value: TypeTask[]) {
        this.form.tasks = value;
        this.updateTaskOrder();
      },
    },
  },
  methods: {
    openDialog() {
      if (this.type) {
        this.tasksLoading = true;
        this.$store.dispatch('adminTypeStorage/getAllTasksByType', this.type.id).finally(() => {
          this.form.icon = this.type.icon;
          this.form.name = this.type.name;
          this.form.tasks = [...this.typeTasks];
          this.tasksLoading = false;
        });
      }
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    resetForm() {
      this.form.icon = '';
      this.form.name = '';
      this.form.tasks = [] as TypeTask[];
      this.$store.commit('adminTypeStorage/setTypeTasks', new Array<TypeTask>());
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(
          'adminTypeStorage/save',
          new TypeModificationRequest(this.form.icon, this.form.name, this.form.tasks, this.type?.id ?? null),
        )
        .then((data) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
    modifyIcon(icon: string) {
      this.form.icon = icon;
    },
    addTask() {
      this.form.tasks.push(new TypeTask(this.largestOrder + 1, '', null));
    },
    removeTask(removedTask: TypeTask) {
      this.form.tasks = this.form.tasks.filter((task: TypeTask) => task.taskOrder !== removedTask.taskOrder);
      this.updateTaskOrder();
    },
    saveEditedName(editedTask: TypeTask, name: string) {
      const edited = this.form.tasks.find((task: TypeTask) => task.taskOrder === editedTask.taskOrder);
      if (edited) {
        edited.taskName = name;
      }
    },
    updateTaskOrder() {
      let index = 1;
      for (const task of this.form.tasks) {
        task.taskOrder = index++;
      }
    },
  },
});
