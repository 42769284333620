import TypeTask from '@/model/type-task';

export default class TypeModificationRequest {
  public icon: string;
  public name: string;
  public tasks: TypeTask[];
  public id?: number;

  constructor(icon: string, name: string, tasks: TypeTask[], id?: number) {
    this.icon = icon;
    this.name = name;
    this.tasks = tasks;
    this.id = id;
  }
}
