












































import Vue from 'vue';
import Table from '@/components/Table/Table.vue';
import TypesTasksForm from '@/components/Admin/TypesTasks/TypesTasksForm.vue';
import PageOptions from '@/model/page/page-options';
import TypeIcon from '@/components/Dashboard/Table/TableColumns/Type/TypeIcon.vue';
import DeleteType from '@/components/Admin/TypesTasks/DeleteType.vue';
import EditButton from '@/components/Button/CircleButton.vue';
import PageableTasks from '@/components/Dashboard/Table/TableColumns/Task/PageableTasks.vue';
import AdminPageButtons from '@/components/Admin/PageButtons.vue';
import Button from '@/components/Button/Button.vue';
import { mapGetters } from 'vuex';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'AdminTypes',
  components: {
    Table,
    TypesTasksForm,
    TypeIcon,
    EditButton,
    DeleteType,
    PageableTasks,
    Button,
    AdminPageButtons,
  },
  props: {
    selectedPage: { type: Object as () => Menu },
  },
  watch: {
    selectedPage() {
      this.page = this.selectedPage;
    },
  },
  data() {
    return {
      loading: true,
      pageOptions: new PageOptions(0, 25),
      page: this.selectedPage,
      plus: '/images/plus.svg',
      tableFields: [
        {
          text: this.$t('admin.type-settings.header.icon'),
          key: 'icon',
          label: this.$t('admin.type-settings.header.icon'),
          thStyle: 'width: 10%',
        },
        {
          text: this.$t('admin.type-settings.header.name'),
          key: 'name',
          label: this.$t('admin.type-settings.header.name'),
          sortable: true,
          thStyle: 'width: 15%',
        },
        {
          text: this.$t('admin.type-settings.header.tasks'),
          key: 'tasks',
          label: this.$t('admin.type-settings.header.tasks'),
          thStyle: 'width: 65%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ typesPageable: 'adminTypeStorage/getTypes', searchText: 'filterStorage/getAdminTypesSearchText' }),
  },
  mounted() {
    this.fetch();
    this.$emit('setFilter', this.searchText);
  },
  methods: {
    fetch(): void {
      this.loading = true;
      this.$store.dispatch('adminTypeStorage/getTypesPage', this.pageOptions).finally(() => (this.loading = false));
    },
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setAdminTypesSearchText', text);
      this.pageOptions = new PageOptions();
      this.fetch();
    },
  },
});
